<script lang="ts">
import { type DeviceBase, type LinkGroupMessage, type Dashboard } from '@wisionmonorepo/api';

export type WidgetDependencies = {
  widgetId: string;
  title: string;
  dragHandleClass: string;
  devices: Ref<DeviceBase[]>;
  linkGroupId: Ref<string>;
  settings: Ref<{ [x: string]: unknown }>;
  messages: Ref<LinkGroupMessage[] | undefined>;
  updateWidgetSettings: (settings: Record<string, unknown>) => void;
  updateMessages: (message: LinkGroupMessage[]) => void;
  manualSelectDevice: (deviceId: string) => void;
  isLoadingDevices: Ref<boolean>;
  lastMessageSender: Ref<string | undefined>;
  manualSelectDeviceId: Ref<number | undefined | null>;
};

export const WIDGET_DEPENDENCIES = Symbol() as InjectionKey<WidgetDependencies>;
export const useWidget = () =>
  inject(WIDGET_DEPENDENCIES) as WidgetDependencies;
</script>

<script setup lang="ts">
import { provide, watch, type InjectionKey, type Ref, inject, ref } from 'vue';
import { useStore } from '@nanostores/vue';
import { $dashboard, $devices, $isLoadingDevices, $lastMessageSender } from '@/features/dashboard/store';
import { listenKeys } from 'nanostores';

const props = defineProps({
  widgetId: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  dragHandleClass: {
    type: String,
    required: true,
  },
});

const dashboardClone = JSON.parse(JSON.stringify($dashboard.get())) as Dashboard;
const devices = useStore($devices);
const isLoadingDevices = useStore($isLoadingDevices);
const groupId = ref(dashboardClone.widgets[props.widgetId].group ?? '');
const settings = ref(dashboardClone.widgets[props.widgetId].props);
const messages = ref(dashboardClone.linkGroupMessages ? dashboardClone.linkGroupMessages[groupId.value] : []);
const lastMessageSender = ref<string | undefined>($lastMessageSender.get());
const manualSelectDeviceId = ref(dashboardClone.widgets[props.widgetId].manualSelectDeviceId);

const manualSelectDevice = (deviceId: string | null) => {
  $dashboard.setKey(`widgets.${props.widgetId}.manualSelectDeviceId`, deviceId === null ? deviceId : +deviceId);
};

const updateWidgetSettings = (settings: Record<string, unknown>) => {
  $dashboard.setKey(`widgets.${props.widgetId}.props`, settings);
};

const updateMessages = (updatedMessage: LinkGroupMessage[]) => {
  const messagesClone = messages.value ? [...messages.value] : [];

  for (const message of updatedMessage) {
    $lastMessageSender.set(message.sender);
    const messageToUpdate = messagesClone.find(m => m.type === message.type);
    if (messageToUpdate) {
      const indexOfMessage = messagesClone.indexOf(messageToUpdate);
      messagesClone[indexOfMessage] = message;
    } else {
      messagesClone.push(message);
    }
  }

  $dashboard.setKey(`linkGroupMessages.${groupId.value}`, messagesClone);
};

listenKeys($dashboard, [`widgets.${props.widgetId}.group`], (dashboard) => {
  if (!dashboard.widgets[props.widgetId]) return;
  groupId.value = dashboard.widgets[props.widgetId]?.group ?? '';
});

listenKeys($dashboard, [`widgets.${props.widgetId}.props`], (dashboard) => {
  if (!dashboard.widgets[props.widgetId]) return;
  settings.value = dashboard.widgets[props.widgetId]?.props ?? {};
});

listenKeys($dashboard, [`widgets.${props.widgetId}.manualSelectDeviceId`], (dashboard) => {
  if (!dashboard.widgets[props.widgetId]) return;
  manualSelectDeviceId.value = dashboard.widgets[props.widgetId]?.manualSelectDeviceId;
});

listenKeys($dashboard, [`linkGroupMessages.${groupId.value}`], (dashboard) => {
  if (!dashboard.linkGroupMessages) return;
  messages.value = dashboard.linkGroupMessages[groupId.value];
});

$lastMessageSender.subscribe((sender) => lastMessageSender.value = sender);

provide(WIDGET_DEPENDENCIES, {
  widgetId: props.widgetId,
  title: props.title,
  dragHandleClass: props.dragHandleClass,
  devices: devices as Ref<DeviceBase[]>,
  linkGroupId: groupId,
  settings: settings as Ref,
  messages,
  updateWidgetSettings,
  updateMessages,
  isLoadingDevices: isLoadingDevices,
  lastMessageSender,
  manualSelectDevice,
  manualSelectDeviceId
});

watch(groupId, () => {
  const dash = $dashboard.get();
  messages.value = dash.linkGroupMessages ? dash.linkGroupMessages[groupId.value] ?? [] : [];
  listenKeys($dashboard, [`linkGroupMessages.${groupId.value}`], (dashboard) => {
    if (!dashboard.linkGroupMessages) return;
    messages.value = dashboard.linkGroupMessages[groupId.value];
  });
});
</script>

<template>
  <slot />
</template>
